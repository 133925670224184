<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :edit-mode="false"
    @entityFetched="updateEntity"
  >
    <div class="mb-3 text-right noprint">
      <b-form-checkbox
        v-model="hideEmpty"
        inline
        switch
      >
        {{ $t('Hide empty fields') }}
      </b-form-checkbox>
    </div>
    <div
      v-for="section in sections"
      :key="section.id"
    >
      <EntityEditSection
        :title="section.title"
        :module="config.module"
        :entity="entity"
        :form-fields="getSectionFields(section.id)"
      />
    </div>
    <div class="mb-3 text-right noprint">
      <b-button @click="printAnswer">
        <feather-icon
          icon="PrinterIcon"
          class="mr-50"
        />
        {{ $t('Print') }}
      </b-button>
    </div>
  </EntitySingle>
</template>

<script>
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'
import { getUserRights } from '@/auth/utils'
import { translationUtils } from '@core/mixins/ui/translations'
import storeModule from './storeModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {
        questionnaire: { id: 0 },
        fields: [],
        colSize: 3,
      },
      allFields: [],
      patientCodeRequired: true,
      hideEmpty: false,
      patientCodeTitle: null,
    }
  },
  computed: {
    questionnaireId() {
      return this.$route.params.qid
    },
    isManager() {
      return getUserRights().includes('Questionnaire_Management_Questionnaire_Edit')
    },
    config() {
      return {
        store: storeModule,
        module: 'questionnaire-answers',
        endpoint: 'questionnaire-answers',
        route: 'submissions',
        title: {
          single: this.$t('Questionnaire Add'),
          plural: this.$t('My Entries'),
        },
      }
    },
    selectMultipleIds() {
      return (
        this.allFields
          .filter(field => field.type === 'SELECTMULTIPLE')
          .map(field => field.id) || []
      )
    },
    fields() {
      return [
        {
          id: 'patientInitials',
          type: 'text',
          title: this.$t('patientInitials'),
          size: this.colSize,
          helpText: this.$t(
            '3 first letters for surname, 3 first letters for name',
          ),
        },
        {
          id: 'patientCode',
          type: 'text',
          title: this.patientCodeTitle || this.$t('patientCode'),
          required: this.patientCodeRequired,
          size: this.colSize,
        },
        {
          id: 'center',
          type: 'text',
          title: this.$t('Center'),
          size: this.colSize,
        },
        {
          id: 'highRiskPatient',
          type: 'text',
          title: this.$t('highRiskPatient'),
          size: this.colSize,
        },
        {
          id: 'comments',
          type: 'textarea',
          title: this.$t('comments'),
          size: 12,
        },
      ].map(field => ({ ...field, ...{ section: { title: this.$t('General info') } } }))
    },
    sections() {
      return [
        ...new Set(this.allFields.map(item => JSON.stringify(item.section))),
      ].map(i => JSON.parse(i))
    }
  },
  methods: {
    async fetchFields(qId) {
      const response = await this.$store.dispatch('app-common/fetchQuestionnaireFields', qId)
      this.patientCodeRequired = response.data.patientCodeRequired
      this.patientCodeTitle = response.data.patientCodeTitle
      let fields = this.fields
      if (!response.data.patientInitialsSupported || !response.data.patientCodeSupported || !response.data.patientCodeSupported) {
        this.colSize = 4
      }
      if (!response.data.patientInitialsSupported) {
        fields = fields.filter(field => field.id != 'patientInitials')
      }
      if (!response.data.highRiskPatientSupported) {
        fields = fields.filter(field => field.id != 'highRiskPatient')
      }
      if (!response.data.patientCodeSupported) {
        fields = fields.filter(field => field.id != 'patientCode')
      }
      this.allFields = [...fields, ...response.data.fields]
    },
    fieldHidden(field) {
      if (field.dependentField) {
        const val = field.dependentFieldValue
        const entityVal = this.entity[field.dependentField.id]
        const isMultiple = Array.isArray(entityVal)
        if (val.includes('!')) {
          if (isMultiple) {
            return !(entityVal && !entityVal.includes(val.replace('!', '')))
          }
          return entityVal == val.replace('!', '')
        } else if (val.includes(',')) {
          const arr = val.split(',')
          if (isMultiple) {
            return !(entityVal && entityVal.some(r => arr.includes(r)))
          }
          return !arr.includes(entityVal)
        }
        if (isMultiple) {
          return !(entityVal && entityVal.includes(val))
        }
        return entityVal != val
      }
      return this.hideEmpty && !this.entity[field.id]?.length
    },
    getSectionFields(sectionId) {
      return this.allFields
        .filter(item => item.section.id === sectionId)
        .map(field => {
          let type = field.type.toLowerCase()
          if (type != 'textarea') {
            type = 'text'
          }
          return {
            id: field.id,
            type,
            label: field.title,
            suffix: field.unit ? field.unit.title : null,
            extraClass: field.required ? 'required-field' : null,
            readonly: true,
            disabled: false,
            colSize: field.size,
            helpText: field.helpText,
            hideToggle: !this.isManager,
            hidden: this.fieldHidden(field),
          }
        })
    },
    async updateEntity(entity) {
      await this.fetchFields(this.entity.questionnaire.id)
      this.setEntity(entity)
      await this.$nextTick()
      setTimeout(() => {
        this.printAnswer()
      }, 1000)
    },
    setEntity(entity) {
      entity.fields.forEach(item => {
        const val = this.selectMultipleIds.includes(parseInt(item.field.id))
          ? item.value.split(',')
          : item.value
        this.entity[item.field.id] = val
      })
      this.entity.fields = []
    },
    printAnswer() {
      window.print()
    },
  },
}
</script>
